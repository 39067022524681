<!-- eslint-disable vuejs-accessibility/mouse-events-have-key-events -->
<template>
  <div
    class="atom-input atom-input__wrapper"
    :class="{ 'has-options-open': showOptions }"
  >
    <div
      v-if="title"
      class="atom-input__title"
    >
      {{ title }}
    </div>

    <div
      ref="wrapperRef"
      class="atom-input-multiselect__wrapper"
      @mouseenter="showOptionsOnHover(true)"
      @mouseleave="showOptionsOnHover(false)"
      @focusin="showOptionsOnHover(true)"
      @focusout="showOptionsOnHover(false)"
    >
      <div
        class="atom-input-multiselect"
        :class="{
          'has-input': hasInput,
          'is-disabled': isDisabled,
          'has-error': showErrors,
        }"
        @keypress="toggleOptions(false)"
        @click="toggleOptions(false)"
      >
        <label class="atom-input-multiselect__label" :for="name">
          {{ label + (isRequired ? ' *' : '') }}
        </label>

        <select
          ref="inputRef"
          v-model="multiselectedOptions"
          class="atom-input-multiselect__input"
          :name="name"
          :disabled="isDisabled"
          :required="isRequired ? 'required' : null"
          multiple="multiple"
          aria-hidden="true"
        >
          <option
            v-for="option in options"
            :key="'real-select-option-' + option.name"
            :value="option.value"
            class="atom-input-multiselect__selected-option"
            :selected="multiselectedOptions.includes(option.value)"
          >
            {{ option.name }}
          </option>
        </select>

        <IonIcon
          class="atom-input-multiselect__icon has-pointer is-right"
          icon-name="chevron-down"
        />
      </div>

      <div
        class="atom-multiselect__options-wrapper"
        :class="{ 'is-visible': showOptions }"
      >
        <div
          ref="optionsRef"
          class="atom-input-multiselect__options"
        >
          <div
            v-for="option in options"
            :key="'option-wrapper-' + option.name"
            class="atom-input-multiselect__option"
            :class="{ 'is-selected': multiselectedOptions.includes(option.value) }"
          >
            <button
              type="button"
              class="atom-input-multiselect__option-button"
              @click="multiselectOption(option)"
              @keypress="multiselectOption(option)"
            >
              <span class="atom-input-multiselect__option-background" />
              <span class="atom-input-multiselect__option-label">{{ option.name }}</span>
              <IonIcon
                class="atom-input-multiselect__icon-close has-pointer is-right"
                icon-name="close"
              />
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="atom-input-multiselect__selected">
      <div
        v-for="option in options"
        :key="'option-selected-' + option.name"
        class="atom-input-multiselect__selected-option"
        :class="{ 'is-selected': multiselectedOptions.includes(option.value) }"
      >
        <button
          v-if="!option.choose_other"
          type="button"
          class="atom-input-multiselect__selected-option-button"
          @click="multiselectOption(option)"
        >
          <span class="atom-input-multiselect__selected-option-background" />
          <span class="atom-input-multiselect__selected-option-label">{{ option.name }}</span>
          <IonIcon
            class="atom-input-multiselect__icon-close has-pointer is-right"
            icon-name="close"
          />
        </button>

        <AtomInputText
          v-else
          class="atom-input-multiselect__other-input"
          :label="option.name"
          :name="option.value"
          @input="setOtherInput"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useWindowSize, onClickOutside } from '@vueuse/core';

const props = defineProps({
    /*
        General data
    */
    label: {
        type: String,
        required: true,
        validator: (value) => value.length > 0,
    },

    name: {
        type: String,
        required: true,
        validator: (value) => value.length > 0 && !value.includes(' '),
    },

    title: {
        type: String,
        default: null,
    },

    initialValue: {
        type: String,
        default: null,
    },

    options: {
        type: Array,
        default: () => [],
        validator: (value) => Array.isArray(value),
    },

    defaultValue: {
        type: String,
        default: null,
    },

    /*
        Validations
    */
    validations: {
        type: Array,
        default: () => [],
        validator: (value) => Array.isArray(value),
    },

    isRequired: {
        type: Boolean,
        default: false,
        validator: (value) => typeof value === 'boolean',
    },

    /*
        Variants
    */
    isDisabled: {
        type: Boolean,
        default: false,
        validator: (value) => typeof value === 'boolean',
    },
});

/*
    Intialze formfield
*/
const wrapperRef = ref(null);
const errors = ref([]);
const inputValue = ref('');

/*
    Handle input
*/
const emit = defineEmits(['set-input', 'set-error']);

const setInitialValue = () => {
    if (props.defaultValue !== undefined
    || props.defaultValue !== null
    || props.defaultValue !== '') {
        const { validationErrors } = useFormfieldEmit(
            [props.defaultValue],
            props.name,
            emit,
            props.validations,
        );

        errors.value = validationErrors;
    } else {
        inputValue.value = props.initialValue;
    }
};

setInitialValue();

/*
    States
*/
const {
    hasInput,
    showErrors,
} = useFormFieldStates(inputValue, errors, props);

/*
    Select all options
*/
const optionsRef = ref(null);
const closedHeight = '4px';
const optionsHeight = ref(closedHeight);

const checkIfAllOptionsSelected = (optionValue) => {
    const selectAllOption = props.options.find(
        (option) => option.value === optionValue && option?.select_all_options,
    );

    if (!selectAllOption) return { selectAll: false, restOptions: false };

    const restOptions = props.options
        .filter((option) => !option?.select_all_options)
        .map((option) => option.value);

    return { selectAll: true, restOptions };
};

/* show and hide options */
const showOptions = ref(false);
const toggleOptions = (forceClose = false) => {
    showOptions.value = forceClose ? false : !showOptions.value;

    optionsHeight.value = showOptions.value
        ? `${optionsRef.value.scrollHeight}px`
        : closedHeight;
};

const showOptionsOnHover = (value) => {
    showOptions.value = value;
};

/* Close dropdown on click outside */
onClickOutside(wrapperRef, () => {
    if (showOptions.value) {
        toggleOptions();
    }
});

/*
    handle multiselected options
*/
const multiselectedOptions = ref([]);

const multiselectOption = (option) => {
    const { selectAll, restOptions } = checkIfAllOptionsSelected(option.value);

    if (!multiselectedOptions.value.includes(option.value)) {
        if (selectAll) {
            if (restOptions.every((opt) => multiselectedOptions.value.includes(opt))) {
                multiselectedOptions.value = [];
            } else {
                multiselectedOptions.value = restOptions;
            }
        } else {
            multiselectedOptions.value.push(option.value);
        }
    } else {
        multiselectedOptions.value = multiselectedOptions.value.filter(
            (item) => item !== option.value,
        );
    }

    if (option.choose_other) {
        toggleOptions(true);
    }
};

/* Watch input */
const handleData = () => {
    if (props.isDisabled) return;

    if (Array.isArray(multiselectedOptions?.value)
        && multiselectedOptions.value.length === 0
        && props.defaultValue !== undefined
        && props.defaultValue !== null
        && props.defaultValue !== '') {
        const { validationErrors } = useFormfieldEmit(
            [props.defaultValue],
            props.name,
            emit,
            props.validations,
        );

        errors.value = validationErrors;
    } else {
        const { validationErrors } = useFormfieldEmit(
            multiselectedOptions.value,
            props.name,
            emit,
            props.validations,
        );

        errors.value = validationErrors;
    }
};

watchEffect(() => {
    handleData();
});

handleData(inputValue.value);

let oldValue = 'other';
if (props.options.some((option) => option.choose_other === true)) {
    oldValue = props.options.find((option) => option.choose_other === true).value;
}

const setOtherInput = (e) => {
    const { value } = e.target;
    const otherOption = props.options.find((option) => option.choose_other === true);

    if (otherOption) {
        otherOption.value = value;
        const existingValueIndex = multiselectedOptions.value.findIndex(
            (option) => option === oldValue,
        );

        if (existingValueIndex !== -1) {
            multiselectedOptions.value[existingValueIndex] = value;
        } else {
            multiselectedOptions.value.push(value);
        }

        oldValue = value;
    }
};

/* Watch window width to set height of box accordingly on resize */
const { width } = useWindowSize();
watch(() => width.value, () => {
    if (!props.isFullScreen) {
        optionsHeight.value = showOptions.value ? `${optionsRef.value.scrollHeight}px` : closedHeight;
    }
});

const colorTopLevel = inject('colorSchema');
const colorTextSelected = computed(() => `var(--c-${colorTopLevel.value}-1)`);
const colorText = computed(() => `var(--c-${colorTopLevel.value}-6)`);
const colorBackground = computed(() => `var(--c-${colorTopLevel.value}-13)`);

const resetOptions = () => {
    multiselectedOptions.value = [];
};

const runReset = inject('runReset');

watch(runReset, () => {
    console.log('runReset', runReset.value);
    resetOptions();
});

defineExpose({
    isRequired: props.isRequired,
    multiselectedOptions,
    resetOptions,
});
</script>

<style lang="scss" scoped>
.atom-input__wrapper {
    @include formFieldWrapper;
    display: flex;
    height: 100%;
    flex-direction: column;
    user-select: none;
}

.atom-input-multiselect__wrapper {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    cursor: pointer;
}

.atom-input-multiselect {
    @include formFieldBasicsText;

    position: relative;
    display: flex;
    max-width: 100%;
    align-items: flex-end;
    background: v-bind(colorBackground);
}

.atom-input-multiselect__other-input {
    margin-top: 15px;
}

.atom-input-multiselect__label {
    @include formFieldLabelText;

    display: flex;
    height: 30px;
    align-items: center;
    padding-top: 2px;
    padding-right: 38px;
    margin-bottom: 0;
    color: v-bind(colorText);
    cursor: pointer;
    font-size: 14px;
    line-height: normal;
    pointer-events: none;
}

.atom-input-multiselect__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
}

.atom-input-multiselect__icon {
    @include formFieldInputIcon(false);

    top: 6px;
    right: 10px !important;
    width: 18px;
    height: 18px;
    transition: transform 0.5s $EASE_IN_OUT--BACK;

    .has-options-open & {
        transform: rotate(180deg);
    }
}
.atom-input-multiselect__trigger {
    @include z-index('selectTrigger');

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 30px;
    cursor: pointer;
}

.atom-multiselect__options-wrapper {
    @include z-index('selectTrigger');

    position: absolute;
    top: 30px;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s $EASE_OUT--SINE;

    &.is-visible {
        opacity: 1;
        pointer-events: auto;
    }
}

.atom-multiselect__options {
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: height 0.5s $EASE_OUT--QUINT;

    .has-options-open & {
        height: 100%;
    }
}

.atom-input-multiselect__option {
    position: relative;
    display: flex;
    align-items: flex-end;
    opacity: 0;
    transition: opacity 0.3s $EASE_OUT--SINE;

    .has-options-open & {
        opacity: 1;
        transition: opacity 0.3s $EASE_IN_OUT--SINE;
    }
}

.atom-input-multiselect__selected-option {
    position: relative;
    display: flex;
    align-items: flex-end;
    margin-top: 3px;
    transition: opacity 0.3s $EASE_OUT--SINE;

    .has-options-open & {
        opacity: 1;
        transition: opacity 0.3s $EASE_IN_OUT--SINE;
    }
}

.atom-input-multiselect__option-divider {
    display: block;
    width: calc(100% - 40px);
    height: 2px;
    margin: 0 auto;
}

.atom-input-multiselect__option-button {
    display: flex;
    width: 100%;
    height: 30px;
    align-items: center;
    justify-content: flex-end;
}

.atom-input-multiselect__selected-option-button {
    display: flex;
    width: 100%;
    height: 30px;
    align-items: center;
    justify-content: flex-end;
}

.atom-input-multiselect__option-background {
    @include z-index('selectBackground');

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0px;
    left: 0;
    background: v-bind(colorBackground);
    transition: background-color 0.2s ease-in-out;
}

.atom-input-multiselect__selected-option-background {
    @include z-index('selectBackground');

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0px;
    left: 0;
    background: v-bind(colorBackground);
    transition: background-color 0.2s ease-in-out;
}

.atom-input-multiselect__option-label {
    @include z-index('selectLabel');

    position: relative;
    padding-right: 38px;
    color: v-bind(colorText);
    font-family: var(--f-family--thin);
    font-size: var(--f-size--tag);
    line-height: var(--l-height--tag);

    .is-selected & {
        color: v-bind(colorTextSelected);
    }
}

.atom-input-multiselect__selected-option-label {
    @include z-index('selectLabel');

    position: relative;
    padding-right: 38px;
    color: v-bind(colorText);
    font-family: var(--f-family--thin);
    font-size: var(--f-size--tag);
    line-height: var(--l-height--tag);

    .is-selected & {
        color: v-bind(colorTextSelected);
    }
}

.atom-input-multiselect__icon-close {
    @include formFieldInputIcon(false);
    @include z-index('selectLabel');

    top: 6px;
    right: 10px !important;
    width: 18px;
    height: 18px;
    opacity: 0;
    transition: transform 0.5s $EASE_IN_OUT--BACK;

    .is-selected & {
        opacity: 1;
    }

}

.atom-input-multiselect__selected {
    .atom-input-multiselect__selected-option {
        display: none;

        &.is-selected {
            display: flex;
        }
    }
}

.atom-input__title {
    margin-bottom: 10px;
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}
</style>
